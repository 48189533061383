/* global $ */

import BaseObject from 'ol/Object'
import Feature from 'ol/Feature'
import Point from 'ol/geom/Point'
import Style from 'ol/style/Style'
import Icon from 'ol/style/Icon'

import { app, glo } from './globo'
import { zeroPad, timePast, dir2orin, dir2oriExtend } from './libra'

const ObjType = 'b'

// from vnt_b
// bal.id,
// bal.src AS s,
// bal.rep AS r,
// bal.name AS n,
// pgc_lat(bal.poz) AS a,
// pgc_lgt(bal.poz) AS g,
// pgc_alt(bal.poz) AS t,
// bal.dir AS d,
// bal.low AS lo,
// bal.speed AS sp,
// bal.gusts AS gu,
// date_part('epoch'::text, bal.touch)::integer AS sa,
// date_part('epoch'::text, bal.touch)::integer AS so,
// date_part('epoch'::text, bal.touch)::integer AS w,
// bal.pres AS pr,
// bal.humi AS hu,
// bal.temp AS te,
// date_part('epoch'::text, bal.ts)::integer AS ts,

// local
let balSpd1Pts = []
let balSpd2Pts = []
let balGusPts = []
let localFactor = -1
const balImgCache = {}

const oris = ['???', 'Nord', 'NNE', 'NE', 'ENE', 'Est', 'ESE', 'SE', 'SSE', 'Sud', 'SSO', 'SO', 'OSO', 'Ouest', 'ONO', 'NO', 'NNO', 'Variable']

function _balEdit (bal) {
  // const w = new Date(bal.sa * 1000)
  // const elapsed = timePast(Math.round(Date.now() - w))
  let txt = '<form id="frm_' + ObjType + '"><fieldset><legend><span id="b_cl"><span class="pc-cl">' + glo.symb.close.c + '</span>&nbsp;Modifier Balise (#' + bal.id + ')</span></legend><table>'
  txt += '<input type="hidden" id="ih_id" value="' + bal.id + '"/>'
  const cnt = 'Balise ' + bal.s + '/' + bal.r + ' ' + ((bal.n !== null) ? bal.n : '')
  txt += '<tr><td colspan="2">' + cnt + '</td></tr>'
  const ori = dir2orin(bal.d)
  txt += '<tr><td><span style="font-weight:bold">Orientation</span></td><td><select id="is_or">'
  for (const o in oris) {
    txt += '<option value="' + o + '"'
    if (ori === parseInt(o)) { txt += ' selected' }
    txt += '>' + oris[o] + '</option>'
  }
  txt += '</select></td></tr>'
  txt += '<tr><td>Mini</td><td><input type="text" id="it_lo" value="' + bal.lo + '" size="3" maxlength="3"> km / h</td></tr>'
  txt += '<tr><td><span style="font-weight:bold">Moyen</span></td><td><input type="text" id="it_sp" value="' + bal.sp + '" size="3" maxlength="3"> km / h</td></tr>'
  txt += '<tr><td>Maxi</td><td><input type="text" id="it_gu" value="' + bal.gu + '" size="3" maxlength="3"> km / h</td></tr>'
  txt += '<tr><td colspan="2" align="right"><span id="b_ok" title="OK">Envoi&nbsp;' + glo.symb.valid.c + '</span></td></tr>'
  txt += '</table></fieldset></form>'
  return txt
}

function balOneLiner (oid) {
  let bal
  if (typeof oid === 'number') {
    bal = app.getObj(ObjType, oid)
    if (!bal) { getBal(oid); return '' }
  } else { bal = oid }
  let cnt = 'Balise '
  const w = new Date(bal.sa * 1000)
  const elapsed = timePast(Math.round(Date.now() - w))
  cnt += bal.s + '/' + bal.r + ' ' + ((bal.n !== null) ? bal.n : '') + ' '
  if (bal.lo !== null) { cnt += Math.round(bal.lo) } else { cnt += '-' }
  cnt += '/' + Math.round(bal.sp) + '/'
  if (bal.gu !== -1) { cnt += Math.round(bal.gu) } else { cnt += '-' }
  cnt += ' ' + dir2oriExtend(bal.d) + ' (' + elapsed + ')'
  return cnt
}

function balDisplay (oid) {
  let bal
  if (typeof oid === 'number') {
    bal = app.getObj(ObjType, oid)
    if (!bal) { getBal(oid); return '' }
  } else { bal = oid }
  app.selectObj(ObjType, bal.id)
  const d = new Date(bal.so * 1000)
  const elapsed = timePast(Math.round(Date.now() - d))
  const od = (d.getDate() < 10 ? '0' : '') + d.getDate() +
    '/' + (d.getMonth() < 9 ? '0' : '') + (d.getMonth() + 1) +
    ' ' + (d.getHours() < 10 ? '0' : '') + d.getHours() +
    ':' + (d.getMinutes() < 10 ? '0' : '') + d.getMinutes()
  const aori = dir2oriExtend(bal.d)
  let gori = aori
  if (typeof bal.gd !== 'undefined' && bal.gd !== null) { gori = dir2oriExtend(bal.gd) }
  let txt = '<fieldset><legend><span id="b_back"></span> &nbsp; <span id="b_cl"><span class="pc-cl">' + glo.symb.close.c + '</span>&nbsp;Balise ' + srcUrl(bal) + ' (#' + bal.id + ')</span></legend><table>'
  txt += '<tr><td>Orientation (direction)</td><td style="color:#0;"><span style="font-weight:bold">' + aori + '</span> (' + parseInt(bal.d) + '&deg;)'
  if (gori !== aori) { txt += ' &nbsp; <b>' + glo.symb.rafa.c + '</b><span title="' + bal.gd + '°">' + gori + '</span>' }
  txt += '</td></tr><tr><td>Vent mini / moyen / maxi</td><td style="color:#0;"> &nbsp;'
  if (bal.lo !== null) { txt += '<span style="font-weight:bold">' + bal.lo.toFixed(1) + '</span>' } else { txt += '-' }
  txt += ' / <span style="font-weight:bold">' + bal.sp.toFixed(1) + '</span> / '
  if (bal.gu !== -1) {
    txt += '<span style="font-weight:bold">' + bal.gu.toFixed(1) + '</span>'
    const raf = bal.gu - bal.sp
    if (raf > 1) { txt += ' &nbsp; <b>' + glo.symb.rafa.c + '</b>' + raf.toFixed(0) }
  } else { txt += '-' }
  txt += ' km/h</td></tr>'
  if (bal.te !== null) { txt += '<tr style="font-size:smaller;"><td>Temp&eacute;rature</td><td>' + parseInt(bal.te) + ' &deg;C</td></tr>' }
  if (bal.hu !== null && bal.hu > 0) { txt += '<tr style="font-size:smaller;"><td>Humidit&eacute;</td><td>' + parseInt(bal.hu) + ' &#37;</td></tr>' }
  if (bal.pr !== null && bal.pr > 900) { txt += '<tr style="font-size:smaller;"><td>Pression</td><td>' + parseInt(bal.pr) + ' hPa</td></tr>' }
  txt += '<tr><td>Quand</td><td style="color:#0;">' + od + ' (<span style="font-weight:bold">' + elapsed + '</span>)</td></tr>'
  txt += '<tr><td colspan="2"><div class="flot-container"><div id="div_bh" class="flot-placeholder"></div>'
  //    txt += '<div align="right">';
  //  txt +=         '<span id="gol2">&lt;&lt;</span>';
  //  txt += ' &nbsp; <span id="gol1">&lt;</span>';
  //  txt += ' &nbsp; <span id="de"></span>';
  //  txt += ' &nbsp; <span id="gor1">&gt;</span>';
  //  txt += ' &nbsp; <span id="gor2">&gt;&gt;</span>';
  //  txt += ' &nbsp; &nbsp; &nbsp;';
  //  txt += ' <span id="gozo">[+]</span> &nbsp; <span id="di"></span> &nbsp; <span id="gouz">[-]</span></div>';
  txt += '</div></td></tr>'
  txt += '<tr style="font-size:smaller;"><td><span id="mc_' + ObjType + '_' + bal.id + '" style="font-size:large">' + glo.symb.navi.c + '</span> Position</td><td>' + bal.a.toFixed(4) + ' ' + bal.g.toFixed(4)
  if (bal.t && bal.t > 0) { txt += '&nbsp; Alt:' + bal.t.toFixed(0) + ' m' }

  const curpos = app.getv('_pos')
  if (curpos) {
    txt += ' <a target="_blank" href="https://www.google.com/maps/preview/dir/' +
            curpos[1].toFixed(6) + ',' + curpos[0].toFixed(6) + '/' + bal.a + ',' + bal.g + '/">&lt;=Nav</a>'
  }
  txt += '</td></tr>'
  if (glo.me.id && glo.me.id === 1) { txt += '<tr><td colspan="2"><span id="ed_' + ObjType + '_' + bal.id + '">' + glo.symb.mod.c + ' DATA</span></td></tr>' }
  txt += '</table></fieldset>'
  updateBalHisto(bal.id, 'bh')
  return (txt)
}

function updateBalHisto (bid, elenam) {
  app.send('gh', { ot: ObjType, id: bid }, function (e, m) {
    if (!e && m && m.body) {
      const r = m.body
      getHistoResult(elenam, r)
    }
  })
}

let theOpts = {}
let theDats = []

function plotHistoResult (elenam) {
// ok     var plo = $('#'+cna+'div').plot(bh_dats, bh_opts);
// ok const plo = $.plot($('#div_' + elenam), theDats, theOpts)
  // $.plot($('#div_' + elenam), theDats, theOpts)
  const e = document.getElementById('div_' + elenam)
  $.plot(e, theDats, theOpts)
}

function modo (x, y) { return (x - y * Math.floor(x / y)) }
function isOriDiff (pda, pdr) {
  const da = parseInt(pda / 22.5); const dr = parseInt(pdr / 22.5)
  const always = false
  if (always) { return true } else { return (da !== dr) }
}

function fillHistoResult (arr) {
// console.log("ARR:"+JSON.stringify(arr));
  const sHtim = []
  const sHmin = []
  const sHmoy = []
  const sHmax = []
  const sHraf = []
  const sH15 = []
  const sH20 = []
  const sH25 = []
  const now = new Date()
  const decms = now.getTimezoneOffset() * 60000
  // let decs = now.getTimezoneOffset()*60;
  // insert FROM to start graph at FROM
  const fromm = now.getTime() - decms - (app.getv('it') * 3600000)
  // let fromm = (now.getTime()/1000) - decs - (app.getv('it')*3600);
  sH15.push([fromm, 15])
  sH20.push([fromm, 20])
  sH25.push([fromm, 25])

  //    for(let i=0; i<sHtim.length; i++) {
  for (let i = 0; i < arr.z.length; i++) {
    const pTms = arr.z[i] * 1000

    if (pTms < 1000000000000 || pTms < fromm) { continue } // ignore old time (0 ?)
    sHtim.push(pTms)
    //        sHtim[i] = pTms;

    // console.log("I#"+i+" "+sHtim[i]+" Ad:"+arr['d'][i]+" Gd:"+arr['r'][i]+" "+arr['l'][i]+"/"+arr['s'][i]+"/"+arr['g'][i]);
    sHmin.push([pTms, arr.l[i]])
    sHmoy.push([pTms, arr.s[i], modo(arr.d[i] + 180.0, 360.0)])
    let gori = null
    if (arr.r[i] !== null && arr.r[i] !== 484 && arr.r[i] !== 494 && isOriDiff(arr.d[i], arr.r[i])) { // 220328
      gori = modo(arr.r[i] + 180.0, 360.0)
    }
    sHmax.push([pTms, arr.g[i], gori])
    sHraf.push([pTms, arr.g[i] - arr.s[i]])
    sH15.push([pTms, 15])
    sH20.push([pTms, 20])
    sH25.push([pTms, 25])
  }

  // add NOW to extend graph to NOW
  const nowm = now.getTime() - decms
  // let nowm = (now.getTime()/1000) - decs;
  sH15.push([nowm, 15])
  sH20.push([nowm, 20])
  sH25.push([nowm, 25])

  theOpts = {
    legend: { show: false, position: 'nw' },
    grid: { hoverable: true },
    tooltip: {
      show: true,
      clickmode: false,
      content: function (label, xval, yval, flotItem) {
        //        onHover: function(label, xval, yval, flotItem) {
        //            console.debug('L='+JSON.stringify(label)+' X='+JSON.stringify(xval)+' Y='+JSON.stringify(yval)+' F='+JSON.stringify(flotItem));
        //            console.debug('I='+flotItem.dataIndex);
        //            console.debug('D='+JSON.stringify(flotItem.series.data));
        //            if( flotItem.series.data[flotItem.dataIndex][2] !== 'undefined' ) {
        //                return '%x %s %y.1 km/h '+flotItem.series.data[flotItem.dataIndex][2]+'°';
        //            } else {
        return '%x %s %y.1 km/h'
        //            }
      }
      //        content: "%x %s %y.1 km/h",
    },
    xaxis: {
      mode: 'time'
      //        timeformat: "%hh%m",
      //        tickSize: [1, "hour"],
      //        axisFontSizePixels: 18,
    },
    yaxis: {
      axisLabel: 'Vitesse (km/h)',
      axisLabelUseCanvas: true,
      axisLabelFontSizePixels: 12,
      axisLabelColor: '#0000',
      position: 'right'
    },
    //        axisLabelColor: "rgb(0,0,0)",
    series: {
      curvedLines: {
        active: true, apply: true, tension: 0.1, legacyOverride: { fit: true }
      },
      points: {
        show: false, radius: 3, fill: false, symbol: 'circle'
      },
      lines: {
        show: true, lineWidth: 2
      },
      rafale: { /* 220328 */
        show: false,
        lineWidth: 1,
        color: 'rgb(0,0,0)',
        fillColor: 'rgb(255,0,0)',
        arrawLength: 6,
        angleType: 'degree',
        openAngle: 33,
        zeroShow: false,
        //            threshold: 0.00000001,
        threshold: 0.01,
        angleStart: 0
      },
      direction: {
        show: false,
        lineWidth: 1,
        color: 'rgb(0,0,0)',
        fillColor: 'rgb(128,255,128)',
        arrawLength: 8,
        angleType: 'degree', // degree or radian
        openAngle: 33,
        zeroShow: false,
        //            threshold: 0.00000001,
        threshold: 0.01,
        angleStart: 0
      }
    }
  }

  theDats = [
    { label: '', data: sH15, color: 'rgba(0,0,250,.7)', lines: { fill: false, lineWidth: 0.4 } },
    { label: '', data: sH20, color: 'rgba(0,250,0,.7)', lines: { fill: false, lineWidth: 0.4 } },
    { label: '', data: sH25, color: 'rgba(250,0,0,.7)', lines: { fill: false, lineWidth: 0.4 } },
    {
      label: 'Maxi',
      data: sHmax,
      color: 'rgb(255,0,0)',
      lines: { show: true, fill: false, color: 'rgb(255,0,0)', fillColor: 'rgba(221,187,255,.6)' },
      direction: { show: false },
      points: { show: false },
      rafale: { show: true, color: 'rgba(255,0,0,.8)', fillColor: 'rgb(255,255,255)', openAngle: 40, arrawLength: 5 } // 220328
    },
    { label: 'Moyenne', data: sHmoy, color: 'rgb(128,255,128)', lines: { fill: false, color: 'rgb(128,255,128)' }, points: { show: false }, direction: { show: true } },
    { label: 'Mini', data: sHmin, color: 'rgb(0,0,255)', lines: { fill: true, fillColor: 'rgba(255,255,255,0.5)' }, direction: { show: false } },
    { label: 'Rafale', data: sHraf, color: 'rgb(32,32,32)', lines: { fill: false, linewidth: 0.25, dashed: [3, 2] }, direction: { show: false } }
  ]
}

function getHistoResult (elenam, res) {
  const x = {}
  // let decms = ((new Date()).getTimezoneOffset()*60000);
  const decs = ((new Date()).getTimezoneOffset() * 60)
  // console.log("TimeDécalage:"+decms);
  x.l = []
  x.s = []
  x.g = []
  x.d = []
  //    x['p'] = [];
  //    x['t'] = [];
  //    x['h'] = [];
  x.z = []
  x.r = [] // 220328
  res.forEach((obj) => {
    /* presque bon */
    x.l.push((obj.l && obj.l !== -1) ? obj.l : obj.s) // TODO: pas dessin lorsque null
    x.s.push(obj.s)
    x.g.push((obj.g && obj.g !== -1) ? obj.g : obj.s)
    x.d.push(obj.d)
    x.r.push(obj.r) // 220328

    /* trous => pas bon
        if( obj.l && obj.l !== -1 ) { x['l'][n] = obj.l; }
        x['s'][n] = obj.s;
        if( obj.g && obj.g !== -1 ) { x['g'][n] = obj.g; }
        x['d'][n] = obj.d;
        if( obj.r && obj.r > -1 ) { x['r'][n] = obj.r; }
*/

    //        x['p'].push( res[n].p); // pres
    //        x['t'].push( obj.t );   // temp
    //        x['h'].push( obj.h );   // humi
    const td = new Date(obj.z)
    x.z.push((td.getTime() / 1000) - decs)
    //        x['z'][n] = (td.getTime()/1000) - decs;
    // console.log("T:"+(td.getTime() - decms)+" obj="+JSON.stringify(obj));
  })
  fillHistoResult(x)
  plotHistoResult(elenam)
}

// function balDrawAll () { $.each(app.getObjs(ObjType), function (n, obj) { obj.draw() }) }

// S, u, w, x, Z, -
function srcUrl (b) {
  let txt
  if (b.s.startsWith('F')) {
    txt = '<a title="FFVL" href="https://www.balisemeteo.com/balise_histo.php?idBalise=' + parseInt(b.r) + '&interval=1&marks=true" target="_blank">' + parseInt(b.r) + '</a>'
  } else if (b.s.startsWith('mf')) {
    // txt = '<a title="MétéoFrance">' + b.r + '</a>'
    txt = '<a title="MétéoFrance" href="https://www.meteociel.fr/temps-reel/obs_villes.php?code2=' + b.r + '&affint=1" target="_blank">' + b.r + '</a>'
  } else if (b.s.startsWith('mc')) {
    txt = '<a title="MétéoCiel" href="https://www.meteociel.fr/temps-reel/obs_villes.php?code2=' + b.r + '&affint=1" target="_blank">' + b.r + '</a>'
  } else if (b.s.startsWith('m6')) {
    txt = '<a title="Météo06" href="https://' + b.r + '.meteo06.fr/" target="_blank">' + b.r + '</a>'
  } else if (b.s.startsWith('ms')) {
    txt = '<a title="PrevMeteoCH" href="https://www.prevision-meteo.ch/satellite/infrarouge" target="_blank">' + b.r + '</a>'
  } else if (b.s.startsWith('cy')) {
    txt = '<a title="CyprusMeteo" href="https://www.dom.org.cy/AWS/ALL_STATIONS_MAP.html" target="_blank">' + b.r + '</a>'
  } else if (b.s.startsWith('w1')) {
    txt = '<a title="WeeWx" href="http://www.depetris.eu/weewx" target="_blank">' + b.r + '</a>'
  } else if (b.s.startsWith('w2')) {
    txt = '<a title="WeeWx" href="https://meteo.correns.org/" target="_blank">' + b.r + '</a>'
  } else if (b.s.startsWith('w3')) {
    txt = '<a title="WeeWx" href="http://accff-lethoronet.fr/" target="_blank">' + b.r + '</a>'
  } else if (b.s.startsWith('iw')) {
    txt = '<a title="iWeathar" href="https://iweathar.co.za/display?s_id=' + b.r + '" target="_blank">' + b.r + '</a>'
  } else if (b.s.startsWith('im')) {
    txt = '<a title="MeteoNetWork" href="https://www.meteonetwork.eu/fr/weather-station/' + b.r + '" target="_blank">' + b.r + '</a>'
  } else if (b.s.startsWith('ae')) {
    txt = '<a title="AeMet">' + b.r + '</a>'
  } else if (b.s.startsWith('W')) {
    txt = '<a title="Wind///Run" href="https://wr.rapacesdazur.fr/wx.html?e=0&n&p=' + app.getv('it') + '&i=' + parseInt(b.r) + '&o=lrhqt&l=' + b.n + '" target="_blank">' + parseInt(b.r) + '</a>'
    // txt += ' (<a title="Env" href="https://wr.rapacesdazur.fr/ge.html?e=0&n&p='+app.getv('it')+'&i=' + parseInt(b.r) + '&l=' + b.n + '" target="_blank">e</a>)';
  } else if (b.s.startsWith('P')) {
    txt = '<a title="Pioupiou" href="https://www.openwindmap.org/PP' + parseInt(b.r) + '" target="_blank">' + parseInt(b.r) + '</a>'
  } else if (b.s.startsWith('_w')) {
    txt = '<a title="WeatherLink" href="https://www.weatherlink.com/user/' + b.r + '/index.php?view=main&headers=0" target="_blank">' + b.r + '</a>'
  } else if (b.s.startsWith('C')) {
    txt = '<a title="M&eacute;t&eacute;ociel" href="https://www.meteociel.fr/temps-reel/obs_villes.php?code2=' + b.r + '" target="_blank">' + b.r + '</a>'
  } else if (b.s.startsWith('D')) {
    txt = '<a title="DiaBox" href="https://data.diabox.com/?id=' + b.r + '" target="_blank">' + b.r + '</a>'
  } else if (b.s.startsWith('U')) {
    txt = '<a title="wUnderground" href="https://www.wunderground.com/dashboard/pws/' + b.r + '" target="_blank">' + b.r + '</a>'
    //  } else if (b.s.startsWith('i') || b.s.startsWith('I')) {
    //    txt = '<a title="infoclimat" href="https://www.infoclimat.fr/observations-meteo/temps-reel/' + b.n + '/' + b.r + '.html" target="_blank">' + b.r + '</a>'
  } else if (b.s.startsWith('R')) {
    txt = '<a title="Romma" href="https://www.romma.fr/station_24.php?tempe=1&pluie=1&humi=1&pressure=1&vent=1&rayonnement=1&id=' + b.r + '" target="_blank">' + b.r + '</a>'
  } else if (b.s.startsWith('x')) {
    txt = '<a title="MeteoX" href="https://fr.meteox.com/fr-fr/forecastlocation/t/' + b.r + '/" target="_blank">' + b.r + '</a>'
  } else if (b.s.startsWith('h')) {
    txt = '<a title="Holfuy" href="https://holfuy.com/fr/weather/' + b.r + '" target="_blank">' + b.r + '</a>'
  } else if (b.s.startsWith('w')) {
    txt = '<a title="worldWeatherOnline" href="https://worldweatheronline.com/" target="_blank">' + b.r + '</a>'
  } else if (b.s.startsWith('S')) {
    txt = '<a title="SYNOP" href="https://www.ogimet.com/cgi-bin/gsynres?ind=' + b.r + '" target="_blank">' + b.r + '</a>'
  } else if (b.s.startsWith('M')) {
    txt = '<a title="Madis">' + b.r + '</a>'
  } else if (b.s.startsWith('A')) {
    txt = '<a title="Aviation">' + b.r + '</a>'
  } else if (b.s.startsWith('c')) {
    txt = '<a title="MeteoConsult" href="https://marine.meteoconsult.fr/meteo-marine/observation-meteo-semaphores-et-bouees.php" target="_blank">' + b.r + '</a>'
  } else if (b.s.startsWith('sc')) {
    txt = '<a title="SenCrop" href="https://sencrop.com" target="_blank">' + b.r + '</a>'
  } else {
    txt = b.s + '/' + b.r
  }
  txt += (b.n !== null) ? ' ' + b.n : ''
  return txt
}

function balIns (ojs) {
  const obj = new Balise(ojs)
  const id = obj.id
  //    prepareBalImg(obj.ik);
  if (app.getObjs(ObjType)[id]) { app.getObjs(ObjType)[id].remove() }
  app.getObjs(ObjType)[id] = obj
  obj.draw()
}

function balUpd (ojs) {
  let obj = app.getObj(ObjType, ojs.id)
  if (obj) {
    obj.update(ojs)
    prepareBalImg(obj.ik)
  } else {
    obj = new Balise(ojs)
    app.getObjs(ObjType)[obj.id] = obj
    obj.draw()
  }
}

function balDel (obj) {
  const ro = app.getObj(ObjType, obj.id)
  if (ro) ro.remove()
}

function balLst (list) {
  if (list) list.forEach((ojs) => balUpd(ojs))
}

function getBal (oid) {
  const co = app.getObj(ObjType, oid)
  if (co) { balUpd(co); return }
  try {
    const eb = app.getEB()
    const sid = app.getv('sid')
    eb.send('go', { id: oid, ot: ObjType }, { sid: '' + sid },
      function (err, r) {
        if (err) { return (null) } else { balIns(r.body[0]) }
      }
    )
  } catch (err) { console.error('GetBal err: ' + err) }
}

function prepareBalImg (k) {
  if (!balImgCache[k] || typeof balImgCache[k] === 'undefined' || balImgCache[k] === null) {
    balImgCache[k] = getBalImg(k)
  }
}

const balStyle = function (f, reso) {
  if (!f || typeof f === 'undefined') return []
  const bal = app.getObj(ObjType, f.getId())
  if (!bal || typeof bal === 'undefined') return []
  const baseSq = app.getv('bSq')
  const factor = app.getv('df')
  const cote = Math.round(baseSq * factor)
  const key = bal.ik
  let i = balImgCache[key]
  if (!i || i === null || typeof i === 'undefined') {
    prepareBalImg(key)
    i = balImgCache[key]
  }
  const rode = app.getv('wt') ? Math.PI : 0

  if (bal.s.startsWith('P') && (!bal.n || bal.n.substr(0, 4) === 'Piou')) {
    i = piouUnNamed(i, cote, cote)
  }

  const itmillis = app.getv('it') * 3600000
  const now = new Date()
  const tch = new Date(bal.so * 1000)
  const agemillis = now.getTime() - tch.getTime()
  if (agemillis > itmillis) {
    bal.pct = 0.0
  } else {
    bal.pct = 1.05 - (agemillis / itmillis)
  }
  const sty = new Style({
    image: new Icon({
      anchor: [0.5, 0.5],
      anchorXUnits: 'fraction',
      anchorYUnits: 'fraction', // pixels
      opacity: bal.pct,
      rotation: bal.d * 0.017453292519943 + rode,
      scale: 1.0,
      imgSize: [cote, cote],
      img: i
    })
  })
  return [sty]
}

function getBalImg (k) {
  return app.getv('wt') ? getConvBalImg(k) : getArrowBalImg(k)
}

function getConvBalImg (k) {
  const baseSq = app.getv('bSq')
  const factor = app.getv('df')
  const sk = k.substr(1, 3)
  const spd = parseInt(sk, 10)
  const cote = Math.round(baseSq * factor)
  const epa = cote / 18.0
  const cen = cote / 2.0
  const grand = cote * 0.2
  const petit = cote * 0.35
  const cnv = document.createElement('canvas')
  const ctx = cnv.getContext('2d', { willReadFrequently: true })
  ctx.canvas.width = cote
  ctx.canvas.height = cote
  ctx.beginPath()
  ctx.moveTo(cen, cote / 20.0)
  if (spd > 74) {
    ctx.lineTo(cen, cote * 4.0 - epa)
    ctx.lineTo(petit, cen + cote / 20.0 - epa)
    ctx.lineTo(petit, cen + cote / 20.0)
    ctx.lineTo(cen, cote * 4.0)
  }
  if (spd > 56) {
    ctx.lineTo(cen, cote * 0.5 - epa)
    if (spd > 65) {
      ctx.lineTo(grand, cote * 0.6 - epa)
      ctx.lineTo(grand, cote * 0.6)
    } else {
      ctx.lineTo(petit, cote * 0.55 - epa)
      ctx.lineTo(petit, cote * 0.55)
    }
    ctx.lineTo(cen, cote * 0.5)
  }
  if (spd > 37) {
    ctx.lineTo(cen, cote * 0.6 - epa)
    if (spd > 46) {
      ctx.lineTo(grand, cote * 0.7 - epa)
      ctx.lineTo(grand, cote * 0.7)
    } else {
      ctx.lineTo(petit, cote * 0.65 - epa)
      ctx.lineTo(petit, cote * 0.65)
    }
    ctx.lineTo(cen, cote * 0.6)
  }
  if (spd > 19) {
    ctx.lineTo(cen, cote * 0.7 - epa)
    if (spd > 28) {
      ctx.lineTo(grand, cote * 0.8 - epa)
      ctx.lineTo(grand, cote * 0.8)
    } else {
      ctx.lineTo(petit, cote * 0.75 - epa)
      ctx.lineTo(petit, cote * 0.75)
    }
    ctx.lineTo(cen, cote * 0.7)
  }
  if (spd > 1) {
    ctx.lineTo(cen, cote * 0.8 - epa)
    if (spd > 9) {
      ctx.lineTo(grand, cote * 0.9 - epa)
      ctx.lineTo(grand, cote * 0.9)
    } else {
      ctx.lineTo(petit, cote * 0.85 - epa)
      ctx.lineTo(petit, cote * 0.85)
    }
    ctx.lineTo(cen, cote * 0.8)
    ctx.lineTo(cen, cote * 0.8 + epa)
    ctx.lineTo(cen + epa, cote * 0.8 + epa)
    ctx.lineTo(cen + epa, cote / 20.0)
  } else {
    ctx.beginPath()
    ctx.arc(cen, cen, cote * 0.2, 0, Math.PI * 2)
  }
  ctx.closePath()
  ctx.fillStyle = '#fff'
  ctx.fill()
  ctx.lineWidth = 1
  ctx.strokeStyle = '#000'
  ctx.stroke()

  const img = new Image()
  img.width = cote
  img.height = cote
  img.src = cnv.toDataURL()
  return img
}

function getArrowBalImg (k) {
  const baseSq = app.getv('bSq')
  const factor = app.getv('df')
  const sk = k.substr(1, 3)
  const gk = k.substr(4, 3)
  const speedI = parseInt(sk, 10)
  const gustsI = parseInt(gk, 10)
  const cote = Math.round(baseSq * factor)
  if (balSpd1Pts.length < 1 || localFactor !== factor) { setBalPts(baseSq, factor) }
  const cnv = document.createElement('canvas')
  const ctx = cnv.getContext('2d', { willReadFrequently: true })
  ctx.canvas.width = cote
  ctx.canvas.height = cote

  ctx.beginPath()
  ctx.fillStyle = balColor(speedI)
  // draw speed arrow
  if (gk !== 'NNN') {
    ctx.moveTo(balSpd1Pts[0][0], balSpd1Pts[0][1])
    for (let i = 1, len = balSpd1Pts.length; i < len; i++) {
      ctx.lineTo(balSpd1Pts[i][0], balSpd1Pts[i][1])
    }
  } else {
    ctx.moveTo(balSpd2Pts[0][0], balSpd2Pts[0][1])
    for (let i = 1, len = balSpd2Pts.length; i < len; i++) {
      ctx.lineTo(balSpd2Pts[i][0], balSpd2Pts[i][1])
    }
  }
  ctx.closePath()
  ctx.fill()
  // draw speed arrow border
  ctx.lineWidth = 1
  ctx.strokeStyle = '#000'
  ctx.stroke()

  // draw gusts arrow
  if (gk !== 'NNN') {
    ctx.beginPath()
    ctx.fillStyle = balColor(gustsI)
    ctx.moveTo(balGusPts[0][0], balGusPts[0][1])
    for (let i = 1, len = balGusPts.length; i < len; i++) {
      ctx.lineTo(balGusPts[i][0], balGusPts[i][1])
    }
    ctx.closePath()
    ctx.fill()
    // draw gusts arrow border
    ctx.lineWidth = 1
    ctx.strokeStyle = '#000'
    ctx.stroke()
  }

  const img = new Image()
  img.width = cote
  img.height = cote
  img.src = cnv.toDataURL()
  return img
}

function piouUnNamed (inpimg, wid, hei) {
  const cnv = document.createElement('canvas')
  cnv.width = wid
  cnv.height = hei
  const ctx = cnv.getContext('2d', { willReadFrequently: true })
  ctx.drawImage(inpimg, 0, 0)
  const imgmap = ctx.getImageData(0, 0, wid - 1, hei - 1)
  const data = imgmap.data
  for (let p = 0, len = data.length; p < len; p += 4) {
    if (data[p] === 0 && data[p + 1] === 0 && data[p + 2] === 0) { // r,g,b,?
      data[p] = 255
    }
  }
  ctx.putImageData(imgmap, 0, 0)

  const img = new Image()
  img.width = wid
  img.height = hei
  img.src = cnv.toDataURL()
  return img
}

function getBalKey (spd, gus) {
  return app.getv('wt') ? getConvBalKey(spd, gus) : getArrowBalKey(spd, gus)
}

function getArrowBalKey (spd, gus) {
  let sk, gk
  if (typeof spd === 'undefined' || spd < 0) { sk = 'NNN' } else { sk = zeroPad(balSpdColorIndex(spd).toFixed(0), 3) }
  if (typeof gus === 'undefined' || gus < 0 || (spd && gus < spd)) { gk = 'NNN' } else { gk = zeroPad(balSpdColorIndex(gus).toFixed(0), 3) }
  return ObjType + sk + gk
}

function getConvBalKey (spd, gus) {
  if (spd > 74.0) { return 'b75' }
  if (spd > 56.0) {
    if (spd > 65.0) {
      return 'b66'
    } else {
      return 'b57'
    }
  }
  if (spd > 37.0) {
    if (spd > 46.0) {
      return 'b47'
    } else {
      return 'b38'
    }
  }
  if (spd > 19.0) {
    if (spd > 28.0) {
      return 'b29'
    } else {
      return 'b20'
    }
  }
  if (spd > 1.0) {
    if (spd > 9.0) {
      return 'b10'
    } else {
      return 'b2'
    }
  } else {
    return 'b0'
  }
}

function setBalPts (bas, fac) {
  const c = Math.round(bas * fac)
  balSpd1Pts = [
    [c / 2.285714, c / 24.0],
    [c / 2.285714, c / 1.043478],
    [c / 2.181818, c / 1.043478],
    [c / 1.371428, c / 1.411765],
    [c / 1.371428, c / 1.454545],
    [c / 1.882353, c / 1.297297],
    [c / 1.882353, c / 24.0]
  ]
  const dec = c / 20.0
  balSpd2Pts = [ // same (cloning not found)
    [c / 2.285714 + dec, c / 24.0],
    [c / 2.285714 + dec, c / 1.043478],
    [c / 2.181818 + dec, c / 1.043478],
    [c / 1.371428 + dec, c / 1.411765],
    [c / 1.371428 + dec, c / 1.454545],
    [c / 1.882353 + dec, c / 1.297297],
    [c / 1.882353 + dec, c / 24.0]
  ]
  const deg = dec * 2.0
  balGusPts = [
    [c / 1.882353 - deg, c / 24.0],
    [c / 1.882353 - deg, c / 1.043478],
    [c / 1.959184 - deg, c / 1.043478],
    [c / 4.173913 - deg, c / 1.411765],
    [c / 4.173913 - deg, c / 1.454545],
    [c / 2.285714 - deg, c / 1.297297],
    [c / 2.285714 - deg, c / 24.0]
  ]
  localFactor = fac
}

const balColors = [
  '#41026b',
  '#a93379',
  '#d63354',
  '#ff3733',
  '#ff6333',
  '#ff8f32',
  '#ffb933',
  '#fed032',
  '#ffe933',
  '#f9fd33',
  '#b8e836',
  '#79d63b',
  '#39c23f',
  '#37d67f',
  '#35ebbf',
  '#33ffff',
  '#fff'
]
function balColor (idx) { return balColors[idx] }

function balSpdColorIndex (spd) {
  let idx = balColors.length - 1
  if (spd > 53) { idx = 0 } else if (spd > 50) { idx = 1 } else if (spd > 47) { idx = 2 } else if (spd > 43) { idx = 3 } else if (spd > 40) { idx = 4 } else if (spd > 37) { idx = 5 } else if (spd > 33) { idx = 6 } else if (spd > 30) { idx = 7 } else if (spd > 27) { idx = 8 } else if (spd > 23) { idx = 9 } else if (spd > 20) { idx = 10 } else if (spd > 17) { idx = 11 } else if (spd > 13) { idx = 12 } else if (spd > 10) { idx = 13 } else if (spd > 7) { idx = 14 } else if (spd > 3) { idx = 15 } else if (spd > 0) { idx = 16 }
  return idx
}

function balSpdColor (spd) {
  return balColor(balSpdColorIndex(spd))
}

class Balise extends BaseObject {
  constructor (ojs) {
    super()
    for (const key in ojs) { this[key] = ojs[key] }
    this.ot = ObjType
    this.ik = getBalKey(this.sp, this.gu)
  }

  draw () {
    prepareBalImg(this.ik)
    const itmillis = app.getv('it') * 3600000
    const now = new Date()
    const tch = new Date(this.so * 1000)
    const agemillis = now.getTime() - tch.getTime()
    if (agemillis > itmillis) {
      this.pct = 0.0
    } else {
      this.pct = 1.05 - (agemillis / itmillis)
    }
    const fea = new Feature({
      geometry: new Point(app.tr2map([this.g, this.a, this.t]), 'XYZ'),
      ot: ObjType
    })
    //        if( ! fea ) { console.error('Bdraw cant new');  return null; }
    fea.setId(this.id)
    fea.set('ot', ObjType)
    app.getSrcs(ObjType).addFeature(fea)
    return fea
  }

  refresh () {
    const fea = app.getSrcs(ObjType).getFeatureById(this.id)
    if (fea) fea.changed()
  }

  undraw () {
    const fea = app.getSrcs(ObjType).getFeatureById(this.id)
    if (fea) app.getSrcs(ObjType).removeFeature(fea)
  }

  update (ojs) {
    for (const key in ojs) { this[key] = ojs[key] }
    this.ik = getBalKey(this.sp, this.gu)
    this.refresh()
  }

  remove () {
    this.undraw()
    app.getObjs(ObjType)[this.id] = null
    delete app.getObjs(ObjType)[this.id]
  }

  getEdit () { return (_balEdit(this)) }
  getDisplay () { return (balDisplay(this)) }
  getOneLiner () { return (balOneLiner(this)) }
}

export { Balise, balStyle, balLst, balIns, balUpd, balDel, balSpdColor, getBal }
